<template>
  <div>
    <div class="e-breadcrumb">密码重置</div>
    <div class="order-table-wrapper">
      <div class="e-card">
        <div class="e-card-body" style="padding-top:35px">
          <el-form label-width="100px">
            <el-form-item label="用户名:">
              <el-input class="form-container" size="small" v-model="loginNameSearch" maxlength="12" show-word-limit></el-input>
              <el-button style="margin-left: 10px;" size="small" type="primary" icon="el-icon-search"
                         @click="getUser()">查询
              </el-button>
            </el-form-item>
            <el-form-item size="small" label="用户名称:">
              <p v-text="loginName"></p>
            </el-form-item>
            <el-form-item size="small" label="用户角色:">
              <p v-text="roleName"></p>
            </el-form-item>
            <el-form-item size="small" label="用户状态:">
              <p v-text="status"></p>
            </el-form-item>
            <el-form-item size="small" label="用户密码:">
              <el-input type="password" size="small" class="form-container" v-model="password" maxlength="11" show-word-limit></el-input>
            </el-form-item>
            <el-form-item size="small" label="">
              <el-button type="primary" size="small" @click="resetPwd">重置密码</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {getUser, resetPwd} from '../../../api/sys/user'

export default {
    data() {
        return {
            list: [],
            map: Object,
            loginNameSearch: '',
            loginName: "",
            roleName: "",
            status: "",
            password: '',
            addVisible: false,
            editVisible: false,
            delVisible: false
        };
    },
    methods: {
        async getUser() {
            let res = await getUser(this.loginNameSearch);
            this.loginName = res.loginName;
            this.roleName = res.roleName;
            this.status = res.status;
        },
        async resetPwd() {
            let res = await resetPwd(this.loginName, this.password);
            if (res.code == 200) {
                this.$message({
                    showClose: true,
                    message: res.message,
                    type: 'success'
                });
            } else {
                this.$message({
                    showClose: true,
                    message: res.message,
                    type: 'error'
                });
            }
        },
        addEvent() {
            this.addVisible = true;
        },
        editEvent() {
            this.editVisible = true;
        },
        removeEvent() {
            this.delVisible = true;
        }
    }
};
</script>

<style scoped>
  .form-container {
    width: 150px;
  }
</style>
