import axios from 'axios'
import {createSearchParams} from '../../utils/main'
import { Base_url} from '../../config'
import {getToken} from "../../utils/auth";

//获取用户列表
function getUserList(userName,isForbidden,page,departmentCode){
    let currentPage = page || 1;
    return new Promise(resolve => {
        axios({
            method: 'get',
            url: Base_url+'/admin/users',
            params:{
                loginName:userName,
                name:name,
                departmentCode:departmentCode,
                isForbidden:isForbidden,
                currentPage:currentPage
            }
        }).then((res)=>{
            resolve(res.data)
        })
    })
}
//获取组织机构
function getDepartmentList(){
    return new Promise(resolve => {
        axios({
            method: 'get',
            url: Base_url+'/admin/getAllDepartment',
        }).then((res)=>{
            resolve(res.data)
        })
    })
}
//获取所有祖级id
function getAllParentDepartmentId(id){
    return new Promise(resolve => {
        axios({
            method: 'get',
            url: Base_url+'/admin/getAllParentDepartmentId',
            params:{
                id:id
            }
        }).then((res)=>{
            resolve(res.data)
        })
    })
}
//保存用户
function saveUser(data){
    return new Promise(resolve => {
        axios({
            method: 'post',
            url: Base_url+'/admin/user',
            data: createSearchParams(data)
        }).then((res)=>{
            resolve(res.data)
        })
    })
}
//获取角色列表
function getRoleList(){
    return new Promise(resolve => {
        axios({
            method: 'get',
            url: Base_url+'/admin/roles',
        }).then((res)=>{
            resolve(res.data)
        })
    })
}
//获取字典表
function getDictDetailList(typeCode){
    return new Promise(resolve => {
        axios({
            method: 'get',
            url: Base_url+'/admin/getDictDetailList/'+typeCode+'/'+new Date(),
        }).then((res)=>{
            resolve(res.data)
        })
    })
}
//删除用户
function deleteUser(userId){
    return new Promise(resolve => {
        axios({
            method: 'post',
            url: Base_url+'/admin/deleteUser',
            params:{
                userId:userId,
            }
        }).then((res)=>{
            resolve(res.data)
        })
    })
}
//更改用户状态
function userStatus(userId,isForbidden){
    return new Promise(resolve => {
        axios({
            method: 'post',
            url: Base_url+'/admin/user/status',
            params:{
                userId:userId,
                isForbidden:isForbidden,
            }
        }).then((res)=>{
            resolve(res.data)
        })
    })
}
//获取单个用户
function getUser(loginName){
    return new Promise(resolve => {
        axios({
            method: 'get',
            url: Base_url+'/admin/getUser',
            params:{
                loginName:loginName,
            }
        }).then((res)=>{
            resolve(res.data)
        })
    })
}
//重置用户密码
function resetPwd(loginName,password){
    return new Promise(resolve => {
        axios({
            method: 'post',
            url: Base_url+'/admin/resetPwd',
            params:{
                loginName:loginName,
                password:password,
            }
        }).then((res)=>{
            resolve(res.data)
        })
    })
}

//修改用户密码
function updatePwd(oldPassword,password,rePassword){
    return new Promise(resolve => {
        let _token = getToken();
        axios({
            method: 'post',
            url: Base_url+'/admin/updatePwd',
            params:{
                token:_token,
                oldPassword:oldPassword,
                password:password,
                rePassword:rePassword
            }
        }).then((res)=>{
            resolve(res.data)
        })
    })
}

function getUserDep(){
    return new Promise(resolve => {
        let _token = getToken();
        axios({
            method: 'get',
            url: Base_url+'/admin/getUserDep',
            params:{
                token:_token,
            }
        }).then((res)=>{
            resolve(res.data)
        })
    })
}



//获取权限列表
function getPermissionList(token){
    return new Promise(resolve => {
        axios({
            method: 'get',
            url: Base_url+'/admin/getPermissionList',
            params:{
                token:token,
            }
        }).then((res)=>{
            resolve(res.data)
        })
    })
}


export {
    getUserList,
    saveUser,
    getRoleList,
    deleteUser,
    getDictDetailList,
    getDepartmentList,
    getAllParentDepartmentId,
    userStatus,
    getUser,
    getPermissionList,
    resetPwd,
    updatePwd,
    getUserDep
}
